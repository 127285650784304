import { CloudFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import { SourceConnector, TargetConnector } from '../NodeCellConnector'

interface Props {
    node: any;
    bindMode: "wait" | "targets" | "view";
    bindingTargets?: any[];
    bindingAccepts: string;
    onAddBindingTarget?: (type: "app" | "scene" | "ir-sensor", target: any) => void;
    onRemoveBindingTarget?: (type: "app" | "scene" | "ir-sensor", target: any) => void;


    
    onSetBindingSource?: (app: any, accepts: string) => void;
    onSetBindingTarget?: (app: any) => void;
    onResetBindingSource?: () => void;
    onViewBindRelations?: (item: any, accepts: string) => void;
    onCancelView?: () => void;
}

export const BasicNodeCell = (props: Props) => {
    const { node } = props;

    const [targetDisabled, setTargetDisabled] = useState(false);
    const [targetSelected, setTargetSelected] = useState(false);
    const [sourceDisabled, setSourceDisabled] = useState(false);
    const [sourceSelected, setSourceSelected] = useState(false);

    const [sourceApp, setSourceApp] = useState<any>(null);

    useEffect(() => {
        const sourceApp = props.node.apps.find((a) => a.type === 'source');
        setSourceApp(sourceApp);
        if(props.bindMode === "wait") {
            setTargetSelected(false);
            setTargetDisabled(true);
            setSourceSelected(false);
            setSourceDisabled(false);
        }else if(props.bindMode === "targets" || props.bindMode === "view") {
            const app = props.node.apps.find((a) => a.type === 'target');

            console.log(sourceApp)
            if(app) {
                let expectKey = app.key;
                if(!(props.bindingAccepts.indexOf("not") > -1)) {
                    expectKey = props.bindingAccepts;
                }
                if(expectKey !== app.key) {
                    setTargetDisabled(true);
                }else {

                    if(props.bindingTargets instanceof Array && props.bindingTargets.length > 0) {
                        if(props.bindingTargets[0].type === "scene") {
                            setTargetDisabled(true);
                        }else {
                            setTargetSelected(false);
                            setTargetDisabled(false);
                            for (let i = 0; i < props.bindingTargets.length; i++) {
                                const bindingTarget = props.bindingTargets[i];
                                if(bindingTarget.target.id === app.id) {
                                    setTargetSelected(true);
                                    break;
                                }
                            }
                        }
                    }else {
                        setTargetDisabled(false);
                        setTargetSelected(false);
                    }
                }

            }else {
                setTargetDisabled(false);
                setTargetSelected(false);

            }

            if(sourceApp) {
                setSourceDisabled(false);
                setSourceSelected(true);

            }else {
                setSourceDisabled(true);
                setSourceSelected(false);
                setSourceApp(null);

            }
        }
    }, [props.node, props.bindingAccepts, props.bindMode, props.bindingTargets]);

    return (
        <div className="node-cell curtain-motor-node-cell">
            <div className="only-connector">
                {
                    !sourceDisabled && sourceApp && (
                        <SourceConnector
                            selected={sourceSelected}
                            disabled={sourceDisabled}
                            connected={sourceApp?.isSource}
                            onClick={() => {
                                const sourceApp = props.node.apps.find((a) => a.type === 'source');
                                if (sourceApp.isSource) {
                                    if (props.bindMode === "view") {
                                        if (typeof props.onCancelView === 'function') {
                                            props.onCancelView();
                                        }
                                    } else if (props.bindMode === "wait") {
                                        if (typeof props.onViewBindRelations === 'function') {
                                            console.log(sourceApp);
                                            props.onViewBindRelations(sourceApp, "not general_ir_human_sensor");
                                        }
                                    }
                                }else {
                                    if (props.bindMode === "wait") {
                                        if (typeof props.onSetBindingSource === 'function') {
                                            props.onSetBindingSource(sourceApp, "not general_ir_human_sensor");
                                        }
                                    }else if(props.bindMode === "targets") {
                                        if(typeof props.onResetBindingSource === "function") {
                                            props.onResetBindingSource();
                                        }
                                    }
                                }
                            }}
                        />
                    )
                }
                {
                    !targetDisabled && (
                        <TargetConnector
                            selected={targetSelected}
                            disabled={targetDisabled}
                            onClick={(_, selected) => {
                                if(props.bindMode === "targets" ||props.bindMode === "view") {
                                    if(!selected) {
                                        if(typeof props.onAddBindingTarget === 'function') {
                                            const app = props.node.apps.find((a) => a.type === 'target');
                                            if(app) {
                                                props.onAddBindingTarget("app", app)
                                            }
                                        }
                                    }else {
                                        if(typeof props.onRemoveBindingTarget === 'function') {
                                            const app = props.node.apps.find((a) => a.type === 'target');
                                            if(app) {
                                                props.onRemoveBindingTarget("app", app);
                                            }
                                        }
                                    }
                                }
                            }}
                        />
                    )
                }
            </div>
            <div className="prod-name">
                {
                    props.node.isOnline && (                        
                        <CloudFilled/>
                    )
                }
                <span>{node.name}</span>
            </div>
            <div className="prod-mac">
                <span>{node.mac || "- 未 关 联 硬 件 -"}</span>
            </div>
            <div className="prod-code">
                <span>{node.deviceCode}</span>
            </div>
        </div>
    )

}