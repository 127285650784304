// export const host = "http://192.168.181.100:1105";
// export const host = process.env.NODE_ENV === "development" ? /*  "http://develop-t.huafeiiot.com:3101" */"http://192.168.50.193:3101" : "https://develop.huafeiiot.com:8800";
// export const host = "https://develop.huafeiiot.com:1105";
export let host = "https://develop.huafeiiot.com:8800";
// export const host = 'http://localhost:3101';
// export const hexHost = process.env.NODE_ENV === 'development' ? /*  */"http://192.168.50.193:3106" : "https://develop.huafeiiot.com:8800";
// export const upmsHost = process.env.NODE_ENV === 'development' ? "http://192.168.50.193:3111" : "https://develop.huafeiiot.com:8800";
export let hexHost =  "https://develop.huafeiiot.com:8800";
export let upmsHost =  "https://develop.huafeiiot.com:8800";

if(process.env.NODE_ENV === "development") {
    // host = "http://localhost:3101";
    // hexHost = "http://localhost:3106";
    // upmsHost = "http://localhost:3111";
}


const _apis = {

    hotelBilling: "/hotel-billing",
    hotelBillingStrategy: "/hotel-billing/strategy",
    hotelDelivery: "/hotel/delivery",


    customer: "/case-customer",
    customerUserInfo: "/case-customer/user-info",
    case: "/case",
    caseNet: "/case/net",
    caseSilkScreen: "/case/silk-screen",
    caseNetCheckActive: "/case/net/check/active",
    area: "/area2",
    caseNode: "/case-node",
    caseScene: "/case-scene",
    caseNodeApp: "/case-node-app",
    caseConfig: "/case-config",
    caseCustomerOutboundList: "/case-customer/outbound/list",
    caseCustomerCases: "/case-customer/cases",
    caseOutboundCustomerNodeList: "/case/outbound/customer/node/list",
    caseOutboundNodeReport: "/case/outbound/node/report",
    caseNodeAppBuildRelation: "/case-node-app/build-relation",
    caseOfflineSpeakerConfig: "/case-offline-speaker/config",
    caseExportScheme: "/case/export/scheme",
    autoConfigAddCaseToAutoConfig: "/auto-config/add-case-to-auto-config",
    autoConfigCaseOnlineTest: "/auto-config/case-online-test",
    caseExtraConfigSetDuerosSpeakerCuid: "/case-extra-config/set/dueros-speaker-cuid",
    caseExtraConfigGetDuerosSpeakerCuid: "/case-extra-config/get/dueros-speaker-cuid",
    autoConfigCaseNetReset: "/auto-config/case-net-reset",
    autoConfigStart: "/auto-config/start",
    autoConfigCancel: "/auto-config/cancel",
    autoConfigStartJoining: "/auto-config/start-joining",
    caseSubmit: "/case/submit",
    caseCustomer: "/case-customer",
    caseCustomerHotel: "/case-customer/hotel",
    caseCustomerSubmit: "/case-customer/submit",
    caseTemplate: "/case-template",
    // caseGroup: "/case-group",
    caseFloor: "/case/floor",
    caseTemplateInstance: "/case-template/instance",

    caseConfigNetPermitJoin: "/case-config/net/permit-join",
    caseConfigNetDisablePermitJoin: "/case-config/net/disable-permit-join",
    caseConfigNetPermitJoinBroadcast: "/case-config/net/permit-join-broadcast",
    caseConfigNetDisableJoinBroadcast: "/case-config/net/disable-join-broadcast",
    caseConfigNodeReset: "/case-config/node/reset",
    caseConfigPrepare: "/case-config/prepare",
    caseConfigNode: "/case-config/node",

    caseProduct: "/case-product",

    authScanCode: "/auth/scan-code",
    authScanStatus: "/auth/scan-status",
    login: "/login",

    hex: "/hex",
    hexProject: "/hex/project",
    hexProjectList: "/hex/project/list",
    hexProjectCascader: "/hex/project/cascader",
    hexProjectSearchHexsForProjects: "/hex/project/search-hex-for-projects",
    hexProjectCate: "/hex/project-cate",
    hexUpload: "/hex/upload",
    hexVersion: "/hex/version",
    hexFile: "/hex/file",
    hexVersionBind: "/hex/version/bind",
    hexVersionUnbind: "/hex/version/unbind",
    hexGroup: "/hex/group",
    hexStage: "/hex/stage",

    hexAllFileUuids:  "/hex/all-file-uuids",
    hexSearchUuid:  "/hex/search/uuid",
    hexDownload:  "/hex/download",
    hexCompareToOutboundStage:  "/hex/compare-to-outbound-stage",

    productSpuCate: "/product/spu-cate",
    productImage: "/product/image",
    productSpuTag:  "/product/spu-tag",
    productSpuUnit: "/product/spu-unit",
    productSpu:        "/product/spu",
    productSpuDetail:  "/product/spu-detail",
    productSpuDevices: "/product/spu-devices",
    productSpuHexProjectBind: "/product/spu-hex-project/bind",
    productProductionPlan:    "/product/production-plan",
    productDeviceInbound:     "/product/device/inbound",
    productDeviceOutbound:    "/product/device/outbound",
    productDeviceHexReport:   "/product/device/hex/report",
    productDeviceCheckType:   "/product/device/check/type",
    productDeviceInfo:        "/product/device/info",
    
    productDevice: "/product/device",

    pcCaseCustomerProcessProgressing: "/pc/case-customer/process/progressing",


    ['/upms/apps/list']: "/upms/apps/list",
    ['/upms/user/list']: "/upms/user/list",
    ['/upms/user/create']: "/upms/user/create",
    ['/upms/login']: "/upms/login",
    ['/upms/user/password']: "/upms/user/password/update",
    ["/upms/user/password/admin"]: "/upms/user/password/admin",
    ['/upms/role/list']: "/upms/role/list",
    ['/upms/role/create']: "/upms/role/create",
    ['/upms/role/app/set']: "/upms/role/app/set",
    ['/upms/tenant/list']: '/upms/tenant/list',
    ['/upms/tenant/channel/list']: '/upms/tenant/channel/list',
    ['/upms/tenant/create']: "/upms/tenant/create"

}


for (const key in _apis) {
    if(_apis[key].indexOf("/hex") === 0) {
        _apis[key] = hexHost + _apis[key];
    }else if(_apis[key].indexOf("/upms") === 0) {
        _apis[key] = upmsHost + _apis[key];
    }else {
        _apis[key] = host + _apis[key];
    }
}

export const apis = _apis;

