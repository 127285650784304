import { message, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { Link, Route, RouteChildrenProps, Switch } from 'react-router-dom'
import { apis } from '../../../utils/apis';
import { store } from '../../../utils/electron';
import { request } from '../../../utils/request';
import { PageInbound2 } from '../Inbound2';
import { PageOnbound } from '../Outbound';
import { PageOutbound2 } from '../Outbound2';
import { HexDownload } from '../HexDownload';
import { PageCaseConfig } from '../CaseConfig';
import { PageBackout } from '../Backout';
// import { apis } from '../../utils/apis';
// import { store } from '../../utils/electron';
// import { request } from '../../utils/request';
import './style.less'
import BurnTool from '../../#burn-tool';
import HelperProjectView from '../ProjectView';


// interface Props extends PageProps<{}> {

// }
interface Props extends RouteChildrenProps {

}

export const HomePage = (props: Props) => {

    return (
        <>
        <Switch>
            <Route exact path="/helper" component={HelperMenu}/>
            <Route exact path="/helper/inbound2" component={PageInbound2}/>
            <Route exact path="/helper/outbound" component={PageOnbound}/>
            <Route exact path="/helper/burn-tool" component={BurnTool}/>
            <Route path="/helper/outbound2" component={PageOutbound2}/>
            <Route exact path="/helper/backout" component={PageBackout}/>
            <Route exact path="/helper/config" component={PageCaseConfig}/>
            
            <Route exact path="/helper/download-hex-files" component={HexDownload}/>

            <Route exact path="/helper/project-view/:customerId/:step" component={HelperProjectView} />
        </Switch>
            
        </>
    )

}

const HelperMenu = (props: Props) => {
    const [checking, setChecking] = useState(false);

    useEffect(() => {
        // if(store) {
        //     setChecking(true);

        //     request.get(apis.hexAllFileUuids)
        //     .then((res) => {
        //         if (res.code === 1) {
        //             store.set("hex-files", res.data);
        //             let downloadHexFileUuids: string[] = [];
        //             if (!store.has("download-hex-file-uuids")) {
        //                 store.set("download-hex-file-uuids", downloadHexFileUuids);
        //             } else {
        //                 downloadHexFileUuids = store.get("download-hex-file-uuids") as string[];
        //             }
        //             let shouldDownloadHexFileUuids: string[] = [];
        //             for (let i = 0; i < res.data.length; i++) {
        //                 const item = res.data[i];
        //                 if (!downloadHexFileUuids.includes(item.uuid)) {
        //                     shouldDownloadHexFileUuids.push(item.uuid);
        //                 }
        //             }
        //             if (shouldDownloadHexFileUuids.length > 0) {
        //                 store.set("should-download-hex-file-uuids", shouldDownloadHexFileUuids);
        //                 props.history.push("/helper/download-hex-files")
        //             }

        //         } else {
        //             message.warn(res.msg || res.message);
        //         }
        //     })
        //     .catch((e) => {
        //         message.warn("请求失败");
        //     })
        //     .finally(() => {
        //         setChecking(false);
        //     });
        // }
    }, []);

    return (
        <Spin spinning={checking} tip="正在检查本地HEX文件">

            <div className="page page-home page-full" style={{ flexDirection: 'column' }}>
                <div className="apps">
                    <Link to="/helper/inbound2">
                        <div className="app">
                            入库
                        </div>
                    </Link>
                    <Link to="/helper/backout" >
                        <div className="app">
                            退库
                        </div>
                    </Link>
                </div>
                <div className="apps">
                    <Link to="/helper/outbound2">
                        <div className="app" style={{width: 548, marginTop: 20}}> 
                            新 - 烧配一体
                        </div>
                    </Link>
                </div>
                {/* <div className="apps" style={{ marginTop: 20 }}>
                    <Link to="/helper/burn-tool">
                        <div className="app">
                            新烧录
                        </div>
                    </Link>
                    <Link to="/helper/config">
                        <div className="app">
                            新配置
                        </div>
                    </Link>
                </div> */}
                <div className="apps" style={{ marginTop: 20 }}>
                    <Link to="/helper/outbound">
                        <div className="app">
                            老烧录
                        </div>
                    </Link>
                    <Link to="/helper/config">
                        <div className="app">
                            老配置
                        </div>
                    </Link>
                </div>
            </div>
        </Spin>
    )

}